import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './component.scss'

const FLOATING_ELEMENT_SELECTOR = '.js-floating-element'

$(window).on('scroll', function () {
  const scrollTop = $(this).scrollTop()
  const windowHeight = $(this).height()
  const $footer = $('footer')

  if ($footer.length) {
    const footerOffsetTop = $footer.offset().top
    const $floatingElement = $(FLOATING_ELEMENT_SELECTOR)
    const scrollThreshold = $floatingElement.data('scroll-threshold')

    if (scrollTop >= scrollThreshold && scrollTop + windowHeight < footerOffsetTop) {
      $floatingElement.fadeIn('slow')
    } else {
      $floatingElement.fadeOut('slow')
    }
  }
})
